import { AutomatitCarousel } from './automatit_carousel.m';

const heroCaro = {
	caro: document.querySelector('#home_hero_caro'),
	init: function() {

		const options = {
			element: this.caro,
			images: [
				{src: "/dist/images/backgrounds/homepage_caro/1209_S_11th_Street.jpg", alt: "1209 S. 11th Street Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/3515_Superior_Ave.jpg", alt: "3515 Superior Ave Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/2515_Union_Ave.jpg", alt: "2515 Union Ave Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/7441_Middle_Road.jpg", alt: "7441 Middle Road Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/1331_Wisconsin_Ave.jpg", alt: "1331 Wisconsin Ave Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/1939_N_18th_St.jpg", alt: "1939 N 18th St. Aerial View"},
				{src: "/dist/images/backgrounds/homepage_caro/3838_Enterprise_Drive.jpg", alt: "3838 Enterprise Dr. Aerial View"},
			],
			imagesAsBackgrounds: true,
			showDots: true,
			useChevrons: false,
		};

		if(options.images.length === 1) {
			options.intervalTiming = false;
		}

		AutomatitCarousel(options);
	}
};

export const initHeroCaro = () => heroCaro.init();