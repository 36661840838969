const _openMobileNav = (evt) => {
	evt.preventDefault();
	const container = document.querySelector('#mobile_nav_container');
	container.setAttribute('active', true);
};

const _closeMobileNav = (evt) => {
	evt.preventDefault();
	const container = document.querySelector('#mobile_nav_container');
	container.removeAttribute('active');
};

const _setupMobileNav = () => {
	const openItem = document.querySelector('#open_mobile_nav');
	const closeItem = document.querySelector('#mobile_nav_close');
	openItem.addEventListener('click', _openMobileNav);
	closeItem.addEventListener('click', _closeMobileNav);
};

const _doubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked') && window.screen.width > 750) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};

export const setupMobileNav = _setupMobileNav;
export const doubleClick = _doubleClick;