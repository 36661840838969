/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_carousel')) {

			const options = {
				element: document.getElementById('facility_carousel'),
				speed: 600,
				images: window.slide_images,
				imagesAsBackgrounds: true,
				intervalTiming: 6000,
				useChevrons: false
			};
			if (window.slide_images.length > 1) {
				options.intervalTiming = 6000;

				document.querySelector('#caro_left').addEventListener('click', function(){
					caro.slideLeft();
				});
				document.querySelector('#caro_right').addEventListener('click', function(){
					caro.slideRight();
				});
			} else {
				options.intervalTiming = 0;
			}
			let caro = AutomatitCarousel(options);

		}
	};

	return my;
})();


export const facilityMap = (function(){
	let my = {};

	function _initMap(){
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: document.getElementById('facility_map'),
					locationElementSelector: '#facility_map',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                        <span class="map_pin">
                            <img src="/static/images/ic_red_mappin.svg" alt="">
                        </span>
                        `
						};
					},
					nativeMapOptions: {
						zoomControlOptions: {
							style: google.maps.ZoomControlStyle.SMALL,
							position: google.maps.ControlPosition.RIGHT_CENTER
						},
						streetViewControl: false
					}
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					theMap.setZoom(15);
				});
			});
	}

	function _setUpMap(){
		_initMap();

		[].forEach.call(document.querySelectorAll('.tabs .tab'), (el) => {
			el.addEventListener('click', (e) => {
				[].forEach.call(document.querySelectorAll('.tabs .tab'), (el) => {
					el.classList.remove('active');
				});
				e.currentTarget.classList.add('active');
				if(e.currentTarget.dataset.content === "map"){
					document.getElementById('facility_map').classList.add('active');
				}else{
					document.getElementById('facility_map').classList.remove('active');
				}
			});
		});
	}

	my.init = () => {
		if(document.getElementById('facility_map')){
			_setUpMap();
		}
	};

	return my;
})();

export const facilityGuidelines = (function(){
	let my = {};

	function _closeSection(el){
		el.classList.remove('active');
		el.nextElementSibling.style.maxHeight = 0;
	}

	function _openSection(el){
		el.classList.add('active');
		const container = el.nextElementSibling;
		const height = container.querySelector('.content_wrapper').offsetHeight;
		container.style.maxHeight = height + "px";
	}

	function _toggleSection(e){
		if(e.currentTarget.classList.contains('active')){
			_closeSection(e.currentTarget);
		}else{
			_openSection(e.currentTarget);
		}
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_guidelines .toggle'), (el) => {
			el.addEventListener('click', _toggleSection);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_guidelines')){
			_assignListeners();
		}
	};

	return my;
})();

export const unitFilters = (function(){
	let my = {},
		size = null,
		type = null;

	function _filterUnits(){
		[].forEach.call(document.querySelectorAll('#unit_table .unit_row,#unit_table .disclaimer'), (el) => {
			if(size === null && type === null){
				el.style.display = 'table-row';
			}else{
				let props = el.dataset.prop;
				el.style.display = 'none';
				if((size === null || props.includes(size)) && (type === null || props.includes(type))){
					el.style.display = 'table-row';
				}

			}
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			type = null;
			currentTarget.classList.add('active');
		}else{
			if(currentTarget.classList.contains('active')){
				currentTarget.classList.remove('active');
				if(currentTarget.classList.contains('type')){
					type = null;
				}else if(currentTarget.classList.contains('size')){
					size = null;
				}
			}else{
				document.querySelector('#all_units_filter').classList.remove('active');
				let active = currentTarget.parentElement.querySelector('.active');
				if(active){
					active.classList.remove('active');
				}
				currentTarget.classList.add('active');
				if(currentTarget.classList.contains('type')){
					type = currentTarget.dataset.filter;
				}else if(currentTarget.classList.contains('size')){
					size = currentTarget.dataset.filter;
				}
			}
		}

		_filterUnits();
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_type_filters')){
			_assignListeners();
		}
	};

	return my;
})();

export const facilityInfoToggle = (function(){
	let my = {};

	my.init = () => {
		if(document.getElementById('facility_top_toggle')){
			const top = document.getElementById('facility_top');
			document.getElementById('facility_top_toggle').addEventListener('click', () => {
				if(top.classList.contains('active')){
					top.classList.remove('active');
				}else{
					top.classList.add('active');
				}
			});
		}
	};

	return my;
})();

export const facilityNav = (function(){
	let my = {};

	function _scrollToSection({currentTarget}){
		let target = currentTarget.dataset.link;
		Velocity(document.querySelector('#'+target), 'scroll');
	}

	my.init = () => {
		if(document.getElementById('facility_page')){
			[].forEach.call(document.querySelectorAll('.facility_nav .facility_link'), (el) => {
				el.addEventListener('click', _scrollToSection);
			});
		}
	};

	return my;
})();