export const helpToggle = (function(){
	let my = {};

	function _toggleSection({currentTarget}){
		let content = currentTarget.nextElementSibling;
		if(currentTarget.classList.contains('open')){
			currentTarget.classList.remove('open');
			content.style.maxHeight = 0;
		}else{
			currentTarget.classList.add('open');
			let height = content.querySelector('.help_content').offsetHeight;
			content.style.maxHeight = height + "px";
		}
	}

	my.init = () => {
		[].forEach.call(document.querySelectorAll('.help_content_toggle'), (el) => {
			el.addEventListener('click', _toggleSection);
		});
	};

	return my;
})();