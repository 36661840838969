const _noopHandler = (evt) => {
	evt.preventDefault();
	evt.stopPropagation();
};

const _bindNoop = () => {
	const els = document.querySelectorAll('[no-op]');
	[...els].forEach(el => {
		el.addEventListener('click', _noopHandler);
	});
};

export const bindNoop = _bindNoop;