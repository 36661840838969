/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const initMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.getElementById('map'),
				locationElementSelector: '.map_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
							<img src="/dist/images/icons/ic_red_mappin.svg" alt="Visit facility" />
							<div class='map_marker'>${el.getAttribute('number')}</div>
						</a>
						`
					};
				},
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if (document.querySelectorAll('.map_source').length < 2) {
					theMap.setZoom(15);
				}

				document.querySelectorAll('.map_source').forEach(i => {
					const markerData = mapHelper.getMarker(i);

					const location_card = document.querySelector(`.location_card[marker_number="${i.getAttribute('number')}"]`);

					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						location_card.classList.add('hover');
						//i.classList.add('hover');
					});

					location_card.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						location_card.classList.add('hover');
						//i.classList.add('hover');
					});

					markerData.element.addEventListener('mouseleave', () => {
						location_card.classList.remove('hover');
						markerData.element.classList.remove('hover');
						//i.classList.remove('hover');
					});

					location_card.addEventListener('mouseleave', () => {
						location_card.classList.remove('hover');
						markerData.element.classList.remove('hover');
						//i.classList.remove('hover');
					});
				});
			});
		});
};