import { contactSubmit } from './contact.js';
import { initMap } from './map';
import { setupMobileNav, doubleClick } from './mobile-nav.js';
import { facilityMap, facilityCaro, facilityGuidelines, unitFilters, facilityInfoToggle, facilityNav } from './facility.js';
import { helpToggle } from './help.js';
import { bindNoop } from './util/noop.js';
import { initHeroCaro } from './home.js';

facilityMap.init();
facilityCaro.init();
facilityGuidelines.init();
unitFilters.init();
facilityInfoToggle.init();
facilityNav.init();

setupMobileNav();
doubleClick();
bindNoop();

helpToggle.init();

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if (document.getElementById('map')) {
	initMap();

	document.querySelectorAll('.location_card').forEach(i => {
		const link = i.querySelector('.location_card_link');
		console.log('link', link);
		i.addEventListener('click', () => {
			link.click();
		});
	});
}


if (document.getElementById('home_facility_select')) {
	document.getElementById('home_facility_select').addEventListener('change', (e) => {
		location.href = e.currentTarget.options[e.currentTarget.selectedIndex]. getAttribute('facility_url');
	});
}

if(document.querySelector('#home_hero_caro')) {
	initHeroCaro();
}